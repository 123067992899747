import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import * as React from 'react';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-help-center': {
                    id?: string;
                    'sg-connect-support': string,
                    'application-id': string;
                };
            }
        }
    }
}

export const HelpCenter = () => {
    return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-help-center/v4/sgwt-help-center.js`}>
        <aside>
            <sgwt-help-center
                id="sgwtSuperHelpCenter"
                introduction-tour="false"
                sg-connect-support="sg-connect-v2"
                application-id="insight-backoffice"
            />
        </aside>
    </WidgetLazyLoader>;
};