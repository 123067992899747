import { useAppContext } from 'components/common/AppProvider';
import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { Permissions, hasAccess, hasAnyRight } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/SgConnect';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { InsightBackofficeSelectors } from 'store/Normalizr/InsightBackofficeSelectors';
import { RoutePaths } from './RoutePaths';
import { useNavigate } from 'react-router';

export const ProtectedRoute: React.FC<{ children: React.JSX.Element, permissions?: Permissions[] }> = ({ children, permissions }) => {
    const { dispatch, state: { appContext: { loggedUserId, isFetching }, entities } } = useAppContext();
    const loggedUser = InsightBackofficeSelectors.getLoggedUser(loggedUserId, entities.insightBackoffice);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!sgConnect?.isAuthorized()) {
                sgConnect?.requestAuthorization();
            }
            else {
                try {
                    const appContext = await dispatch(getAppContext());
                    if (!hasAccess(appContext?.loggedUser)) {
                        redirectUnauthorizedUser();
                    }
                    else if (permissions && !hasAnyRight(appContext.loggedUser, permissions)) {
                        navigate(RoutePaths.ResearchCompanies.AllCompanies.url());
                    }

                } catch (error) {
                    redirectUnauthorizedUser();
                }
            }
        })();
    }, []);

    if (isFetching) {
        return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
    }

    return hasAccess(loggedUser) && (!permissions || hasAnyRight(loggedUser, permissions))
        ? children
        : null;
};
