import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import * as React from 'react';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-splash-screen': { application: string };
            }
        }
    }
}

export const SplashScreen = () => {
    return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`}>
        <sgwt-splash-screen
            application="insight-backoffice"
        />
    </WidgetLazyLoader>;
};
